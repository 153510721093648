export const getImpersonationProps = (key: string) => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    console.error(`Failed to read ${key} from local storage`);
    console.error(e);

    return null;
  }
};
