import type { FC } from 'react';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@truvhq/ui';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      'html': {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        'height': '100%',
        'width': '100%',
      },
      'body': {
        height: '100%',
        width: '100%',
        fontFamily: theme.typography.fontFamily,
      },
      '#root': {
        height: '100%',
        width: '100%',
        position: 'relative',
      },
    },
  }),
);

export const GlobalStyles: FC = () => {
  useStyles();

  return null;
};
