import { useEffect } from 'react';

import { Intercom, shutdown, update } from '@intercom/messenger-js-sdk';
import { getUnixTime } from 'date-fns';

import { useGetWidgetSettings } from 'hooks/customization';
import { useFeatures } from 'hooks/features';
import { useAuth } from 'shared/lib/auth/useAuth';
import { getFullName } from 'shared/lib/format/getFullName';

export const IntercomChat = () => {
  const { isAuthenticated, user } = useAuth();
  const { data: features } = useFeatures(isAuthenticated);
  const { data: widgetSettings } = useGetWidgetSettings();
  const hasIntercom = !!features?.flags?.intercom && !!import.meta.env.VITE_APP_INTERCOM_KEY && isAuthenticated;

  useEffect(() => {
    if (!hasIntercom) {
      return;
    }

    Intercom({
      app_id: import.meta.env.VITE_APP_INTERCOM_KEY,
      source: 'Truv Dashboard',
    });

    return () => {
      shutdown();
    };
  }, [hasIntercom]);

  useEffect(() => {
    if (!hasIntercom || !user) {
      return;
    }

    const { profile, company } = user;

    update({
      user_id: profile?.id,
      created_at: getTimestamp(profile?.created_at),
      email: profile?.email,
      phone: profile?.phone_number,
      name: getFullName(profile?.first_name, profile?.last_name),
      // don't send Intercom fake companies from test environments
      company: import.meta.env.PROD
        ? {
            company_id: company?.id,
            created_at: getTimestamp(company?.created_at),
            name: company?.name,
            plan: company?.permission_group?.name,
            industry: widgetSettings?.use_case,
            allowed_product_types: widgetSettings?.allowed_product_types.join(),
          }
        : undefined,
    });
  }, [hasIntercom, user, widgetSettings]);

  return null;
};

const getTimestamp = (date?: string | number | Date) => {
  return date ? getUnixTime(date) : undefined;
};
