import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext, useState } from 'react';

import { clsx } from 'clsx';

import { SandboxHeader } from 'components/SandboxHeader/SandboxHeader';
import { Promo, usePromo } from 'hooks/promo';
import { useQueryParams } from 'hooks/useQueryParams';

import { NavBar } from './NavBar';

const LayoutContext = createContext<HTMLDivElement | null>(null);

export const TwoPaneLayout: FC<PropsWithChildren> = ({ children }) => {
  const { promoHeader, activePromo } = usePromo();
  // using state instead of ref because we need to update it after children are rendered
  const [layoutEl, setLayoutEl] = useState<HTMLDivElement | null>(null);
  const { action } = useQueryParams();
  const isCreateOrder = action === 'create';

  return (
    <LayoutContext.Provider value={layoutEl}>
      <div
        className={clsx('fixed top-0 w-full', {
          'z-[1301]': isCreateOrder, // MUI backdrop has 1300, and we need the header to be above
          'z-50': !isCreateOrder,
        })}
      >
        {promoHeader}
        <SandboxHeader />
      </div>
      <div
        className={clsx('flex h-full bg-white', {
          'sm:pt-10': activePromo?.id === Promo.FREDDIE_HEADER,
          'sm:pt-[68px]': activePromo?.id === Promo.FANNIE_HEADER,
        })}
      >
        <div className="flex h-full max-h-screen">
          <NavBar />
        </div>
        <div ref={setLayoutEl} className="relative flex min-w-page max-w-page flex-auto flex-col overflow-auto">
          {children}
        </div>
      </div>
    </LayoutContext.Provider>
  );
};

export const useLayout = () => {
  return useContext(LayoutContext);
};
