function trimPhone(prefixedPhone: string) {
  return prefixedPhone.replace(/[()\-\s]/g, '');
}

export function normalizePhone(phone: string) {
  if (phone === '' || !phone) {
    return '';
  }

  let prefixedPhone;

  if (phone.startsWith('+1')) {
    prefixedPhone = phone;
  } else if (phone.startsWith('1')) {
    prefixedPhone = `+${phone}`;
  } else {
    prefixedPhone = `+1${phone}`;
  }

  return trimPhone(prefixedPhone);
}
