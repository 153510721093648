import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';

import { unpackPhone } from './unpackPhone';

export const useMaskedPhone = ({ phone }: { phone?: string }) => {
  const [maskedPhone, setMaskedPhone] = useState('');

  useEffect(() => {
    if (phone && !maskedPhone) {
      setMaskedPhone(unpackPhone(phone));
    }
  }, [maskedPhone, phone]);

  const onPhoneChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setMaskedPhone(event.target.value);
  };

  return {
    onPhoneChange,
    maskedPhone,
  };
};
