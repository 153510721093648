import { useGetProfile } from 'entities/user/api/useGetProfile';
import { getEnv } from 'shared/lib/env/getEnv';
import { useSandboxContext } from 'shared/lib/sandbox/useSandboxContext';

export const useCurrentEnv = (): 'dev' | 'prod' | 'sandbox' => {
  const { isSandboxOn } = useSandboxContext();
  const { data: profile } = useGetProfile();

  return getEnv(isSandboxOn, profile?.env_types);
};
