import type { FC, PropsWithChildren } from 'react';
import { createContext, useCallback, useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { baseApiClient } from 'api/BaseApiClient';
import { CustomizationQueryKeys } from 'hooks/customization';
import { useFeatures } from 'hooks/features';
import { useUpdateProperties } from 'hooks/properties';

import { useAuth } from '../lib/auth/useAuth';
import { getEnv } from '../lib/env/getEnv';

type SandboxContextType = {
  isSandboxOn: boolean;
  isSandboxVisible: boolean;
  tooltipMessage: string;
  onSandboxSwitch: (isSandboxMode: boolean) => boolean;
};

const defaultContextValue: SandboxContextType = {
  isSandboxOn: true,
  isSandboxVisible: true,
  tooltipMessage: '',
  onSandboxSwitch: () => true,
};

export const SandboxContext = createContext(defaultContextValue);

export const SandboxContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const [isSandboxOn, setIsSandboxOn] = useState<boolean>(defaultContextValue.isSandboxOn);
  const [tooltipMessage, setTooltipMessage] = useState(defaultContextValue.tooltipMessage);
  const { updateProperties } = useUpdateProperties();

  const setSandbox = useCallback(
    (isSandbox: boolean) => {
      const env = getEnv(isSandbox, user?.profile?.env_types);

      baseApiClient.setEnv(env);
      setIsSandboxOn(isSandbox);
    },
    [user],
  );

  const role = user?.profile?.role?.id;
  const isOrdersManager = role === 'orders_manager' || role === 'orders_tasks_manager';
  const isIndividualVerifier = user?.isCompanyIndividual;
  const { data: features } = useFeatures();

  const isSandboxVisible =
    !(isOrdersManager && user?.hasProductionKey) &&
    !(isIndividualVerifier && !features?.flags?.sandbox_switch_individual_company);

  useEffect(() => {
    if (user) {
      if ((user.hasProductionKey || user.hasDevelopmentKey) && (isOrdersManager || !user.properties?.isSandboxMode)) {
        setSandbox(false);
      } else {
        setSandbox(true);
      }
    }
  }, [user, isOrdersManager]);

  const onSandboxSwitch = (isSandboxMode: boolean) => {
    setTooltipMessage('');

    if (isOrdersManager) {
      const orderManagerTooltip =
        user?.hasProductionKey || user?.hasDevelopmentKey
          ? 'You can only use production mode once the Production API keys are issued'
          : 'Production mode will be enabled once the Production API keys are issued';

      setTooltipMessage(orderManagerTooltip);

      return false;
    }

    if (user?.hasProductionKey || user?.hasDevelopmentKey) {
      setSandbox(isSandboxMode);
      updateProperties(
        { isSandboxMode },
        {
          onError(error) {
            // eslint-disable-next-line no-console
            console.error('failed to save sandbox state', error);
          },
          onSuccess() {
            void queryClient.invalidateQueries({
              queryKey: [CustomizationQueryKeys.getWidgetSettings],
            });
          },
        },
      );

      return true;
    }

    return false;
  };

  return (
    <SandboxContext.Provider
      value={{
        isSandboxOn,
        isSandboxVisible,
        tooltipMessage,
        onSandboxSwitch,
      }}
    >
      {children}
    </SandboxContext.Provider>
  );
};
