import type { FC } from 'react';

import { ErrorPage } from '@truvhq/ui';

import userErrorImage from 'assets/images/user-error.png';

import { FullScreenContentLayout } from 'layouts';

export const ErrorNoAccess: FC = () => (
  <FullScreenContentLayout>
    <ErrorPage
      buttonText="Open HR dashboard"
      icon={<img alt="No access" height={64} src={userErrorImage} width={64} />}
      pageTitle="No access"
      subTitle="You seem to have an Truv HR dashboard account. Try logging in there."
      title="You don’t have access to Truv Dashboard"
      onClick={() => window.location.replace(import.meta.env.VITE_APP_HR_DASHBOARD_URL || 'https://hr.truv.com')}
    />
  </FullScreenContentLayout>
);
