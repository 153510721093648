import { useMemo } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import concat from 'lodash-es/concat';
import maxBy from 'lodash-es/maxBy';

import { apiClient } from 'api/ApiClient';
import type { EnvType, KeyDescription } from 'api/types';
import { allEnvTypes } from 'api/types';
import { useHasAdditionalDetails } from 'components/KeysAdditionalDetailsForm/hooks';
import { useGetProfile } from 'entities/user/api/useGetProfile';
import { useCurrentEnv } from 'features/sandbox/lib/useCurrentEnv';
import { useGetCompany, useGetCompanySettings } from 'hooks/company';
import { useUpdateProperties } from 'hooks/properties';
import { useAuth } from 'shared/lib/auth/useAuth';

export enum CanCreateKey {
  Yes = 'yes',
  NeedRequestFirstKey = 'needRequestFirstKey',
  NeedToDeleteOld = 'needToDeleteOld',
}

export type Keys = {
  allItems: KeyDescription[];
  hasNewProdOrDevKeys: boolean;
  clientId?: string;
  sandbox?: string;
  development?: string;
  production?: string;
  hasProductionKey: boolean;
  hasDevelopmentKey: boolean;
  hasSandboxKey: boolean;
  allKeys: Record<EnvType, KeyDescription[]>;
  canCreate: Record<EnvType, CanCreateKey>;
};

type UseKeys = {
  isLoading: boolean;
  keys: Keys | undefined;
  refetch(): void;
};

type UseKeysParams = {
  refetchOnMount?: boolean | 'always';
};

export const useKeys = (params: UseKeysParams = {}): UseKeys => {
  const { user } = useAuth();
  const { data: company } = useGetCompanySettings();

  const isDeveloper = user?.profile?.role.id === 'developer';
  const skipProdKeys = isDeveloper && !company?.show_prod_keys_to_developers;

  const { data, isPending, refetch } = useQuery({
    queryKey: ['getKeys'],
    queryFn: () => apiClient.getUserKeys(),
    ...params,
    select: (data) =>
      skipProdKeys ? { dev: data.dev, sandbox: data.sandbox, prod: { ...data.prod, active_keys: [] } } : data,
  });

  return useMemo(() => {
    const allItems = concat(data?.dev.active_keys ?? [], data?.prod.active_keys ?? [], data?.sandbox.active_keys ?? []);

    return {
      isLoading: isPending,
      refetch,
      keys: data && {
        allItems,
        canCreate: allEnvTypes.reduceRight(
          (acc, env) => {
            if (data[env].can_create_new_key) {
              acc[env] = CanCreateKey.Yes;
            } else {
              acc[env] =
                data[env].active_keys.length === 0 ? CanCreateKey.NeedRequestFirstKey : CanCreateKey.NeedToDeleteOld;
            }

            return acc;
          },
          {} as Record<EnvType, CanCreateKey>,
        ),
        allKeys: allEnvTypes.reduceRight(
          (acc, env) => {
            acc[env] = data[env].active_keys;

            return acc;
          },
          {} as Record<EnvType, KeyDescription[]>,
        ),
        clientId: allItems?.[0]?.client_id,
        hasNewProdOrDevKeys:
          data.dev.active_keys.some((x) => x.last_used_at === null) ||
          data.prod.active_keys.some((x) => x.last_used_at === null),
        hasProductionKey: data.prod.active_keys.length > 0,
        hasDevelopmentKey: data.dev.active_keys.length > 0,
        hasSandboxKey: data.sandbox.active_keys.length > 0,
        sandbox: maxBy(data.sandbox.active_keys, (x) => x.created_at)?.access_key,
        development: maxBy(data.dev.active_keys, (x) => x.created_at)?.access_key,
        production: maxBy(data.prod.active_keys, (x) => x.created_at)?.access_key,
      },
    };
  }, [data, isPending, refetch]);
};

export const useCreateKey = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (env: EnvType) => {
      return apiClient.createUserKeys(env);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['getKeys'] });
    },
  });
};

export const useDeleteKey = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (key: string) => {
      return apiClient.deleteUserKey(key);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['getKeys'] });
    },
  });
};

type ProdKeysZapParams = {
  firstName?: string | null;
  lastName?: string | null;
  role?: string | null;
  email?: string | null;
  companyName?: string | null;
  env: 'production' | 'development';
};

export function requestKeys({ firstName, lastName, role, email, companyName, env }: ProdKeysZapParams) {
  const zapUrl = import.meta.env.VITE_APP_PROD_KEYS_REQUEST_ZAPIER;

  if (!zapUrl) {
    // eslint-disable-next-line no-console
    console.error('no zapier url provided');

    return Promise.resolve({});
  }

  return fetch(zapUrl, {
    method: 'POST',
    headers: {},
    body: JSON.stringify({
      firstName,
      lastName,
      role,
      email,
      companyName,
      requestType: `${env} keys request`,
    }),
  });
}

export const useKeysRequest = (env: 'production' | 'development') => {
  const { user } = useAuth();
  const { data } = useGetProfile();
  const { data: company } = useGetCompany();
  const { updateProperties } = useUpdateProperties();

  const { detailsToAdd, hasDetails } = useHasAdditionalDetails();

  const companyName = user?.company?.name || company?.name;

  const { mutateAsync, isPending } = useMutation({
    mutationFn: () => {
      return requestKeys({
        firstName: data?.first_name,
        lastName: data?.last_name,
        companyName,
        email: data?.email,
        role: data?.role.name,
        env: env,
      }).then(() =>
        updateProperties({ [env === 'development' ? 'isDevRequestSubmitted' : 'isProductionRequestSubmitted']: true }),
      );
    },
  });

  return {
    request: mutateAsync,
    isRequesting: isPending,
    detailsToAdd,
    hasDetails,
  };
};

export const useCurrentKey = () => {
  const { keys } = useKeys();
  const currentEnv = useCurrentEnv();

  if (currentEnv === 'dev') {
    return { currentKey: keys?.development, keys };
  }

  if (currentEnv === 'prod') {
    return { currentKey: keys?.production, keys };
  }

  return { currentKey: keys?.sandbox, keys };
};
