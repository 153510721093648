import type { FC } from 'react';

import { useHistory } from 'react-router-dom';

import { useAuth } from 'shared/lib/auth/useAuth';

import { ErrorView } from './ErrorView';

type ErrorNotAuthenticatedProps = {
  error?: string;
};

export const ErrorNotAuthenticated: FC<ErrorNotAuthenticatedProps> = ({ error }) => {
  const history = useHistory();
  const { logout } = useAuth();

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
      history.push('/');
    } catch (err) {
      console.error(err);
    }
  };

  let title = 'Authentication failed';
  let subTitle = error || '';

  const buttonText = 'Try again';
  const pageTitle = 'Unauthorized';

  if (error === 'no_signups_with_google') {
    title = 'Invalid email address';
    subTitle = 'Use your work email address to login.';
  } else if (error === 'no_public_emails_allowed') {
    title = 'Authentication failed. Invalid email address';
    subTitle = 'Please enter your business or work email address.';
  } else if (error === 'password_expired') {
    title = 'Authentication failed. Password Expired';
    subTitle = 'Your password has expired and must be changed.';
  } else if (error === 'no_social_logins_before_signup') {
    title = 'Authentication failed. Unidentified login, registration required';
    subTitle = 'You need to register on Truv website in order to continue logging in using Google.';
  } else if (error === 'wrong_ip_address') {
    title = 'Authentication failed. Unable to login';
    subTitle = 'Unable to log in. Please join to your work network and retry to login.';
  }

  return (
    <ErrorView buttonText={buttonText} pageTitle={pageTitle} subTitle={subTitle} title={title} onClick={handleLogout} />
  );
};
