import type { FC } from 'react';

import { Box } from '@mui/material';
import { LoadingScreen } from '@truvhq/ui';

export const SplashScreen: FC = () => {
  return (
    <div className="fixed left-0 top-0 z-[2000] flex h-full w-full flex-col items-center justify-center">
      <Box width={400}>
        <LoadingScreen />
      </Box>
    </div>
  );
};
