import type { FC, ReactNode } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { SnackbarProvider } from 'notistack';

import { theme } from './index';

const useSnackbarStyles = makeStyles(() => ({
  root: {
    '& .SnackbarContent-root': {
      minWidth: 'auto',
      [theme.breakpoints.up('sm')]: {
        minWidth: 'auto',
      },
    },
  },
}));

export const ThemedSnackbarProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const classes = useSnackbarStyles();

  return (
    <SnackbarProvider dense anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }} classes={classes} maxSnack={3}>
      {children}
    </SnackbarProvider>
  );
};
