import { useQuery } from '@tanstack/react-query';

import { apiClient } from 'api/ApiClient';

export function useGetBillingLink(returnUrl: string) {
  return useQuery({
    queryKey: ['getBillingLink', returnUrl],
    queryFn: () => apiClient.getBillingLink({ return_url: returnUrl }),
    refetchOnWindowFocus: false,

    // turned off by default, manual refetch is needed
    enabled: false,
  });
}
