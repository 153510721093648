import { normalizePhone } from '../format/normalizePhone';

const phoneRegex = /^\+1\d{10}$/;

export function isValidPhone(phone?: string) {
  if (!phone) {
    return false;
  }

  const normalizedPhone = normalizePhone(phone);

  return phoneRegex.test(normalizedPhone);
}
