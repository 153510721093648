import { impersonationEmailKey, impersonationIdKey } from './common';

export const setImpersonationParams = (newEmail: string, newId: string) => {
  try {
    localStorage.setItem(impersonationEmailKey, newEmail);
    localStorage.setItem(impersonationIdKey, newId);
  } catch (e) {
    console.error('Failed to write impersonation params to local storage');
    console.error(e);
  }
};
