import type { Analytics, EventProperties, ID, UserTraits } from '@segment/analytics-next';
import { AnalyticsBrowser } from '@segment/analytics-next';
import type { History } from 'history';

import { getIsFullStoryEnabled } from './getIsFullStoryEnabled';

const FULLSTORY_SAMPLE_RATE = 0.1; // record 10% of sessions

type InitParams = {
  history: History;
  enablesFullStory?: boolean;
  isIndividual?: boolean;
  fullStorySampleRate?: number;
};

class AnalyticsClient {
  private analytics: Analytics | undefined;

  private pageView = () => {
    void this.analytics?.page();
  };

  public init = ({ history, enablesFullStory = true, fullStorySampleRate = FULLSTORY_SAMPLE_RATE }: InitParams) => {
    return AnalyticsBrowser.load(
      { writeKey: import.meta.env.VITE_APP_SEGMENT_KEY ?? '' },
      {
        integrations: {
          FullStory: enablesFullStory && getIsFullStoryEnabled(fullStorySampleRate),
        },
      },
    )
      .then(([analytics]) => {
        this.analytics = analytics;

        if (analytics.integrations.FullStory) {
          this.track('FullStory enabled');
        }

        // listen all url changes
        history.listen(this.pageView);
        // track current page
        this.pageView();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  public identify = (userId?: ID, traits?: UserTraits) => {
    void this.analytics?.identify(userId, traits);
  };

  public track = (type: string, props?: EventProperties) => {
    if (!import.meta.env.PROD) {
      // eslint-disable-next-line no-console
      console.log('analytics event:', type, props);
    }

    void this.analytics?.track(type, props);
  };
}

const client = new AnalyticsClient();

export default client;
