import type { FieldError } from 'react-hook-form';

export const getPhoneError = (phoneError: FieldError | undefined) => {
  if (!phoneError) {
    return null;
  }

  if (typeof phoneError === 'object' && 'type' in phoneError && phoneError.type === 'isCorrect') {
    return 'We accept phone numbers in this format: +14155552671';
  }

  return 'Phone number is required';
};
