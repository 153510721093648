import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@truvhq/ui';
import { colors } from '@truvhq/ui';

import { Footer } from 'components/UnverifiedEmail/Footer';

import { CompleteFormWrapper } from './CompleteFormWrapper';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flexGrow: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  formContainer: {
    width: '832px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      width: '80%',
      minWidth: '410px',
    },
  },

  link: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    lineHeight: '24px',
    color: colors.green.text,
    marginTop: '24px',
    cursor: 'pointer',
    display: 'block',
  },
}));

export type CompleteProfileProps = {
  showCompanyField: boolean;
};

export const CompleteProfile = ({ showCompanyField }: CompleteProfileProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid
        container
        alignItems="center"
        direction="column"
        justifyContent="center"
        spacing={0}
        style={{ flexGrow: 1, height: '100%' }}
      >
        <div className={classes.formContainer}>
          <CompleteFormWrapper showCompanyField={showCompanyField} />
        </div>
      </Grid>
      <Footer pageName="complete profile" />
    </div>
  );
};
