import type { Primitive } from './common';

export const encodeQueryParams = (obj: Record<string, Primitive | Primitive[]>) => {
  const params = new URLSearchParams();

  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        params.append(key, String(item));
      });
    } else {
      params.append(key, String(value));
    }
  });

  return params.toString();
};
