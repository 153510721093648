import { useEffect } from 'react';

import { setTags, setUser } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import { apiClient } from 'api/ApiClient';

export function useGetProfile() {
  const result = useQuery({ queryKey: ['getProfile'], queryFn: () => apiClient.getProfile() });
  const { isSuccess, data } = result;

  useEffect(() => {
    if (isSuccess && data) {
      const { email, company: { id: company } = {}, is_target_account } = data;

      setTags({ email, company, is_target_account });
      setUser({ email });
    }
  }, [data, isSuccess]);

  return result;
}
