import type { FC, PropsWithChildren } from 'react';

import { useAuth } from 'shared/lib/auth/useAuth';
import type { Permission } from 'types/permissions';
import { NoPermissionAtAllView } from 'views/errors/NoPermissionAtAllView';
import { NoPermissionView } from 'views/errors/NotPermissionView';

type AuthGuardProps = PropsWithChildren<{
  permission?: Permission;
  companyPermission?: Permission;
}>;

export const PermissionGuard: FC<AuthGuardProps> = ({ children, permission, companyPermission }) => {
  const { permissions, companyPermissions } = useAuth();

  if (permissions.size === 0) {
    return <NoPermissionAtAllView />;
  }

  if (
    (permission && !permissions.has(permission)) ||
    (companyPermission && !companyPermissions.has(companyPermission))
  ) {
    return <NoPermissionView />;
  }

  return <>{children}</>;
};
