import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://aeee9ba45d3d4977a286c8889efaaade@o1082479.ingest.sentry.io/4504016388227072',
  integrations: [Sentry.browserTracingIntegration()],
  release: import.meta.env.VITE_APP_VERSION,
  environment: import.meta.env.VITE_APP_ENV,
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: import.meta.env.VITE_APP_ENV === 'prod' ? 0.2 : 0,
  replaysOnErrorSampleRate: 1,
  enabled: !document.referrer.includes('localhost') && !document.referrer.includes('127.0.0.1'),
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
});
