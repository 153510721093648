import type { FC } from 'react';
import { useEffect } from 'react';

import type { History, LocationState } from 'history';

import { useFeatures } from 'hooks/features';
import analytics from 'shared/lib/analytics/analytics';
import { useAuth } from 'shared/lib/auth/useAuth';

export const Analytics: FC<{ history: History<LocationState> }> = ({ history }) => {
  const { isAuthenticated, user } = useAuth();
  const { data: features, isSuccess } = useFeatures(isAuthenticated);

  useEffect(() => {
    if (isAuthenticated && !isSuccess) {
      return;
    }

    void analytics
      .init({
        history,
        enablesFullStory: isAuthenticated,
        fullStorySampleRate:
          user?.company?.company_type === 'individual' ? features?.flags?.paygo_fullstory_sample_rate : undefined,
      })
      .then(() => {
        if (user && isAuthenticated) {
          analytics.identify(user.id, {
            avatar: user.avatar,
            company: {
              id: user.company?.id,
              name: user.company?.name,
            },
            displayName: user.name,
            email: user.email,
            name: user.name,
            productName: 'dashboard',
            role: user.profile?.role,
            companyType: user.company?.company_type,
          });
        }
      });
  }, [history, isAuthenticated, user, isSuccess, features]);

  return null;
};
