import type { FC } from 'react';
import { useEffect } from 'react';

import { useAuth } from 'shared/lib/auth/useAuth';

export const DelightedSurvey: FC = () => {
  const { user } = useAuth();

  useEffect(() => {
    if (user?.profile?.is_approved && window.delighted) {
      window.delighted.survey({
        email: user.profile.email,
        name: `${user.profile.first_name} ${user.profile.last_name}`,
        properties: { company: user.company?.name, role: user.profile.role?.name },
      });
    }
  }, [user]);

  return null;
};
