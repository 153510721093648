import type { FC } from 'react';

import { ErrorView } from './ErrorView';

type Props = {
  onReset: () => void;
};

export const ReactErrorView: FC<Props> = ({ onReset }) => (
  <div className="h-screen">
    <ErrorView
      buttonText="Back to home"
      linkTo="/"
      pageTitle="We hit a snag"
      subTitle="Refresh the page and try again."
      title="We hit a snag"
      onClick={onReset}
    />
  </div>
);
