import type { FC } from 'react';

import { useHistory } from 'react-router-dom';

import type { UnauthenticatedErrorCode } from 'shared/lib/auth/common';
import { useAuth } from 'shared/lib/auth/useAuth';

import { ErrorView } from './ErrorView';

type Error401UserProps = {
  error?: UnauthenticatedErrorCode;
};

export const Error401UserView: FC<Error401UserProps> = ({ error }) => {
  const { logout } = useAuth();
  const history = useHistory();

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
      history.push('/');
    } catch (err) {
      console.error(err);
    }
  };

  let title = 'Access denied';
  let subTitle = 'Please contact your account owner.';
  let buttonText = 'Try again';
  let pageTitle = 'Unauthorized';

  switch (error) {
    case 'no_signups_with_public_email':
      title = 'Invalid email address';
      subTitle = 'Use your work email address to login.';
      break;
    case 'invite_expired':
      title = 'Your invite has been expired';
      subTitle = 'Reach out the account owner to resend an invite.';
      buttonText = 'Logout';
      pageTitle = 'Invite expired';
      break;
  }

  return (
    <ErrorView buttonText={buttonText} pageTitle={pageTitle} subTitle={subTitle} title={title} onClick={handleLogout} />
  );
};
