/* eslint-disable */
import { impersonationIdKey } from 'shared/lib/impersonation/common';
import { getImpersonationProps } from 'shared/lib/impersonation/getImpersonationProps';

import type { EnvType } from './types';
type ImpersonationHeaders = {
  'X-Dashboard-Impersonation-Id'?: string;
};
type DownloadAcceptHeaders = 'application/pdf' | 'application/zip';
type CallbackVoid = (response: any) => void;
type CallbackPromiseString = () => Promise<string>;

const AuthenticationRequiredException = new Error('AuthenticationRequiredException');

export class BaseApiClient {
  private token = '';
  private env: EnvType = 'sandbox';

  private callbackOn401: CallbackVoid | null = null;
  private callbackGetAuthToken: CallbackPromiseString = () => Promise.resolve('');

  constructor(private apiRoot: string) {}

  setCallbackAuthToken(callback: CallbackPromiseString) {
    this.callbackGetAuthToken = callback;
  }

  setEnv(env: EnvType) {
    this.env = env;
  }

  setCallbackOn401(callback: CallbackVoid) {
    this.callbackOn401 = callback;
  }

  protected async getAuthHeaders() {
    const token = await this.callbackGetAuthToken();

    return token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : null;
  }

  protected getImpersonationHeaders = (): ImpersonationHeaders => {
    const impersonationId = getImpersonationProps(impersonationIdKey);

    if (impersonationId) {
      return {
        'X-Dashboard-Impersonation-Id': impersonationId,
      };
    }

    return {};
  };

  protected getEnvHeaders = () => {
    return { 'X-Dashboard-Key-Env': this.env };
  };

  protected async getHeaders() {
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      ...(await this.getAuthHeaders()),
      ...this.getImpersonationHeaders(),
      ...this.getEnvHeaders(),
    };
  }

  protected async getDownloadHeaders(accept: DownloadAcceptHeaders, extraDownloadHeaders?: Record<string, string>) {
    return {
      Accept: accept,
      ...extraDownloadHeaders,
      ...(await this.getAuthHeaders()),
      ...this.getImpersonationHeaders(),
      ...this.getEnvHeaders(),
    };
  }

  protected getAbsoluteUrl = (url: string) => {
    if (url.startsWith('http')) {
      return url;
    }

    return `${this.apiRoot}${url}`;
  };

  request<T>(url: string, options?: Partial<RequestInit>, params?: any): Promise<T> {
    const u = new URL(this.getAbsoluteUrl(url));

    // TODO: just naive implementation
    Object.keys(params || {})
      .filter((key) => params[key] !== null && params[key] !== undefined)
      .forEach((key) => {
        const currentValue = params[key];

        if (Array.isArray(currentValue)) {
          currentValue.forEach((val) => u.searchParams.append(key, val));
        } else {
          u.searchParams.append(key, currentValue);
        }
      });

    function isOwnServer(lastUrl: string, apiRoot: string) {
      return lastUrl.startsWith(apiRoot);
    }

    return fetch(u.href, {
      credentials: 'omit',
      ...options,
    }).then((resp) => {
      if (resp.status === 204) {
        return null;
      }

      if (resp.status < 299 && resp.status >= 200) {
        if (params && params.responseType === 'blob') {
          return resp.blob();
        }

        return resp.json();
      }

      //session has expired
      if (resp.status === 401 && isOwnServer(u.toString(), this.apiRoot)) {
        if (!this.callbackOn401) {
          // eslint-disable-next-line no-console
          console.warn('use `this.callbackOn401` for general callback on 401');
          throw AuthenticationRequiredException;
        }

        const callback = this.callbackOn401;

        return resp.json().then((data) => {
          callback(data);
          throw data;
        });
      }

      return resp.json().then((data) => {
        throw data;
      });
    });
  }

  async patch<T, K = FormData | Record<string, unknown>>(url: string, data: K): Promise<T> {
    const headers = await this.getHeaders();

    let body: string | FormData = '';

    if (data instanceof FormData) {
      body = data;
      delete (headers as Partial<typeof headers>)['Content-Type'];
    } else if (data) {
      body = JSON.stringify(data);
    } else {
      delete (headers as Partial<typeof headers>)['Content-Type'];
    }

    return this.request<T>(url, {
      headers,
      body,
      method: 'PATCH',
    });
  }

  async delete<T>(url: string, data: any = undefined): Promise<T> {
    const headers = await this.getHeaders();

    let body;

    if (data) {
      body = JSON.stringify(data);
    }

    return this.request<T>(url, {
      headers,
      body,
      method: 'DELETE',
    });
  }

  async post<T, K = FormData | Record<string, unknown>>(url: string, data: K): Promise<T> {
    const headers = await this.getHeaders();

    let body: string | FormData = '';

    if (data instanceof FormData) {
      body = data;
      delete (headers as Partial<typeof headers>)['Content-Type'];
    } else if (data) {
      body = JSON.stringify(data);
    } else {
      delete (headers as Partial<typeof headers>)['Content-Type'];
    }

    return this.request<T>(url, {
      headers,
      body,
      method: 'POST',
    });
  }

  async put<T, K = FormData | Record<string, unknown>>(url: string, data: K): Promise<T> {
    const headers = await this.getHeaders();

    let body: string | FormData = '';

    if (data instanceof FormData) {
      body = data;
      delete (headers as Partial<typeof headers>)['Content-Type'];
    } else if (data) {
      body = JSON.stringify(data);
    } else {
      delete (headers as Partial<typeof headers>)['Content-Type'];
    }

    return this.request<T>(url, {
      headers,
      body,
      method: 'PUT',
    });
  }

  async get<T>(url: string, params?: unknown, options?: Partial<RequestInit>): Promise<T> {
    const headers = await this.getHeaders();

    return this.request(
      url,
      {
        headers,
        method: 'GET',
        ...options,
      },
      params,
    );
  }

  async download<T>(
    url: string,
    method: 'GET' | 'POST' = 'GET',
    accept: DownloadAcceptHeaders = 'application/pdf',
  ): Promise<T> {
    const headers = await this.getDownloadHeaders(accept);

    return this.request(
      url,
      {
        headers,
        method,
      },
      {
        responseType: 'blob',
      },
    );
  }
}

export const baseApiClient = new BaseApiClient(import.meta.env.VITE_APP_API_URL ?? '');
