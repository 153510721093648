import type { EnvType } from 'api/types';

export const getEnv = (isSandboxOn: boolean, envTypes?: EnvType[]): EnvType => {
  if (!isSandboxOn && !envTypes?.includes('prod')) {
    return 'dev';
  }

  if (!isSandboxOn) {
    return 'prod';
  }

  return 'sandbox';
};
