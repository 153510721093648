import { useAuth } from 'shared/lib/auth/useAuth';

const LoginView = () => {
  const { login } = useAuth();

  void login();

  return <></>;
};

export default LoginView;
