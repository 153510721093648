import type { FC } from 'react';

import tadaImage from 'assets/images/tada.png';

type Props = {
  href: string;
  onClick: () => void;
};

export const FreddieHeader: FC<Props> = ({ href, onClick }) => (
  <a
    className="group hidden h-10 w-full flex-nowrap items-center justify-center bg-gradient-to-r from-primary-main to-grey100 text-center text-white no-underline sm:flex"
    href={href}
    rel="noreferrer"
    target="_blank"
    onClick={onClick}
  >
    <div className="px-4">
      <span className="opacity-70 group-hover:opacity-100">
        Truv is now an approved third-party service provider supporting Freddie Mac Loan Product Advisor® asset and
        income modeler&nbsp;(AIM)
      </span>
      &nbsp;
      <img alt="Tada emoji" className="-mb-0.5" height="16" src={tadaImage} />
      &nbsp;
      <span className="underline opacity-90 group-hover:opacity-100">View&nbsp;announcement</span>
    </div>
  </a>
);
