import { useCallback, useEffect, useState } from 'react';

import { useGetBillingLink } from 'hooks/billing';
import { urls } from 'shared/urls';

export const useNavigateToBilling = () => {
  const { data, refetch } = useGetBillingLink(window.location.origin + urls.settings.url());

  const [billingTab, setBillingTab] = useState<Window | null>(null);

  const handleBillingClick = useCallback(() => {
    setBillingTab(window.open());

    return refetch();
  }, [refetch]);

  useEffect(() => {
    if (data?.url && billingTab) {
      billingTab.location.href = data.url;
      setBillingTab(null);
    }
  }, [billingTab, data]);

  return handleBillingClick;
};
