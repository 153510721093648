import type { FC } from 'react';

import { Box, Button, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Page } from '@truvhq/ui';
import { Link as RouterLink } from 'react-router-dom';

type ErrorViewProps = {
  pageTitle: string;
  title: string;
  subTitle?: string;
  buttonText?: string;
  linkTo?: string;
  onClick?: () => void;
};

export const ErrorView: FC<ErrorViewProps> = ({ pageTitle, title, subTitle, buttonText, linkTo, onClick }) => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Page className="h-full" title={pageTitle}>
      <Container className="flex h-full flex-col items-center justify-center" maxWidth="lg">
        <Typography align="center" color="textPrimary" variant={mobileDevice ? 'h4' : 'h2'}>
          {title}
        </Typography>
        {subTitle && (
          <Typography align="center" className="mt-2 font-normal" color="textSecondary" variant="subtitle2">
            {subTitle}
          </Typography>
        )}
        {buttonText && (
          <Box display="flex" justifyContent="center" mt={6}>
            {linkTo ? (
              <Button component={RouterLink} to={linkTo} variant="contained" onClick={onClick}>
                {buttonText}
              </Button>
            ) : (
              <Button variant="contained" onClick={onClick}>
                {buttonText}
              </Button>
            )}
          </Box>
        )}
      </Container>
    </Page>
  );
};
