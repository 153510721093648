import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiClient } from 'api/ApiClient';
import type { UserProperties } from 'api/types';

export const useUpdateProperties = () => {
  const queryClient = useQueryClient();
  const oldUserProperties = queryClient.getQueryData(['getProperties']);

  const { mutate } = useMutation({
    mutationFn: (newUserProperties: UserProperties) => {
      queryClient.setQueryData(['getProperties'], () => {
        return newUserProperties;
      });

      return apiClient.updateProperties(newUserProperties);
    },
    onError: (error) => {
      queryClient.setQueryData(['getProperties'], () => {
        return oldUserProperties;
      });
      console.error(error);
    },
  });

  return {
    updateProperties: mutate,
  };
};
