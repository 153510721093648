import { useEffect } from 'react';

import { Box, MenuItem, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@truvhq/ui';
import { LoadingScreen, Select, colors } from '@truvhq/ui';
import { Controller, useForm } from 'react-hook-form';

import { AnalyticsButton } from 'components/AnalyticsButton';
import { useProfileSuggest } from 'entities/user/api/useProfileSuggest';

import type { CompleteFormData } from '../lib/common';

type Props = {
  submitting: boolean;
  isLoading: boolean;
  showCompanyField: boolean;
  onSubmit: (formData: CompleteFormData) => void;
};

const markets = [
  { name: 'Employment verifications', value: 'employment verifications' },
  { name: 'VOIE for mortgage', value: 'voie for mortgage' },
  { name: 'VOIE for consumer & auto', value: 'voie for consumer' },
  { name: 'Tenant screening', value: 'tenant screening' },
  { name: 'Building payroll integrations', value: 'payroll integrations' },
  { name: 'Direct deposit switch', value: 'dds' },
];

const roles = [
  { name: 'Developer', value: 'developer' },
  { name: 'Product', value: 'product' },
  { name: 'Operations', value: 'operations' },
  { name: 'Other', value: 'other' },
];

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: '100%',
  },
  cardContent: {
    padding: '32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  companyRow: {
    width: '100%',
    display: 'flex',
  },
  companyContainer: {
    flexGrow: 1,
    paddingRight: '8px',
    margin: '8px 0',
  },
  divider: {
    margin: '32px -32px',
    backgroundColor: colors.grey30,
  },
  fieldsContainer: {
    width: '80%',
    maxWidth: '448px',
  },
  formContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  headerContainer: {
    width: '100%',
    textAlign: 'center',
  },
  header: {
    marginBottom: '16px',
  },
  input: {
    margin: '16px 0 0 0',
  },
  fieldHint: { fontSize: '14px', color: theme.palette.text.secondary },
  notifySwitch: {
    lineHeight: '32px',
  },
  notifySwitchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  optionalHint: {
    color: colors.grey50,
  },
}));

export const CompleteForm = ({ submitting, isLoading, onSubmit, showCompanyField }: Props) => {
  const classes = useStyles();
  const { suggest } = useProfileSuggest();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<CompleteFormData>({
    shouldUnregister: true,
    mode: 'onChange',
    defaultValues: {
      type: markets[0].value,
      role: roles[0].value,
    },
  });

  useEffect(() => {
    reset({
      companyName: suggest?.client_name,
      firstName: suggest?.first_name,
      lastName: suggest?.last_name,
    });
  }, [suggest, reset]);

  const isDisabled = submitting || isLoading || Object.keys(errors).length !== 0;

  return (
    <form className={classes.card} onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.card}>
        <div className={classes.cardContent}>
          <Box className={classes.headerContainer}>
            <Typography className={classes.header} variant="h1">
              Tell us about yourself
            </Typography>
          </Box>

          {isLoading ? (
            <Box display="flex" justifyContent="center" my={5}>
              <LoadingScreen />
            </Box>
          ) : (
            <Box className={classes.fieldsContainer}>
              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    className={classes.input}
                    error={errors.firstName != null}
                    helperText={errors.firstName?.type === 'required' && 'First name is required'}
                    label="First Name"
                    variant="outlined"
                  />
                )}
                rules={{
                  required: true,
                }}
              />

              <Box>
                <Controller
                  control={control}
                  name="lastName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      className={classes.input}
                      error={errors.lastName != null}
                      helperText={errors.lastName?.type === 'required' && 'Last name is required'}
                      label="Last Name"
                      variant="outlined"
                    />
                  )}
                  rules={{
                    required: true,
                  }}
                />
              </Box>
              {showCompanyField && (
                <Box>
                  <Controller
                    control={control}
                    name="companyName"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        className={classes.input}
                        error={errors.companyName != null}
                        helperText={errors.companyName?.type === 'required' && 'Company name is required'}
                        label="Company name"
                        variant="outlined"
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                </Box>
              )}
              <Box>
                <Controller
                  control={control}
                  name="type"
                  render={({ field }) => (
                    <Select
                      {...field}
                      fullWidth
                      className={classes.input}
                      error={errors.type != null}
                      helperText={errors.type?.type === 'required' && 'Please specify the problem'}
                      id="type"
                      label="What problem are you solving?"
                    >
                      {markets.map((market) => (
                        <MenuItem key={market.value} value={market.value}>
                          {market.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  rules={{
                    required: true,
                  }}
                />
                <Controller
                  control={control}
                  name="role"
                  render={({ field }) => (
                    <Select
                      {...field}
                      fullWidth
                      className={classes.input}
                      error={errors.role != null}
                      helperText={errors.role?.type === 'required' && 'Role is required'}
                      id="role"
                      label="What role are you in?"
                    >
                      {roles.map((role) => (
                        <MenuItem key={role.value} value={role.value}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  rules={{
                    required: true,
                  }}
                />
              </Box>
              <Box mb={1} mt={4}>
                <AnalyticsButton
                  fullWidth
                  color="primary"
                  disabled={isDisabled}
                  eventName="onboarding - click continue"
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Continue to dashboard
                </AnalyticsButton>
              </Box>
            </Box>
          )}
        </div>
      </div>
    </form>
  );
};
