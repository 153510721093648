import type { Filters } from './common';

export const mergeFilters = (filters: Filters, newFilters: Partial<Filters>): Filters => ({
  ...filters,
  ...Object.entries(newFilters).reduce<Partial<Filters>>((prev, [key, value]) => {
    if (value !== undefined) {
      // @ts-expect-error
      prev[key] = value;
    }

    return prev;
  }, {}),
});
