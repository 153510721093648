import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { apiClient } from 'api/ApiClient';
import type { WidgetSettings } from 'api/types';
import { CustomizationQueryKeys } from 'hooks/customization';

export const useUpdateLogo = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { isPending, mutateAsync } = useMutation({
    mutationFn: (newLogo?: File | null): Promise<WidgetSettings> => {
      if (newLogo) {
        return apiClient.uploadLogo(newLogo);
      }

      return apiClient.deleteLogo();
    },
    onSuccess: (data) => {
      queryClient.setQueryData([CustomizationQueryKeys.getWidgetSettings], () => data);
    },
    onError: (error) => {
      enqueueSnackbar('Logo update failed');
      console.log(error);
    },
  });

  return {
    isLogoUpdating: isPending,
    updateLogo: (newLogo?: File | null) => mutateAsync(newLogo),
  };
};
