import { useQuery } from '@tanstack/react-query';

import { apiClient } from 'api/ApiClient';
import { useGetProfile } from 'entities/user/api/useGetProfile';
import type { PromoConfig } from 'hooks/promo';

export type FeaturesResponse = {
  promo?: PromoConfig[];
  flags?: {
    sandbox_switch_individual_company?: boolean;
    create_order_dialog?: boolean;
    paygo_fullstory_sample_rate?: number;
    intercom?: boolean;
    users_beta_companies?: string[];
  };
};

export const useFeatures = (enabled = true) => {
  return useQuery({
    queryKey: ['getFeatures'],
    queryFn: () => apiClient.getAnalyticsSource<FeaturesResponse>('features', {}),
    retry: false,
    enabled,
  });
};

export const useHasAllUsersFeature = () => {
  const { data: features } = useFeatures();
  const { data: profile } = useGetProfile();

  return new Set(features?.flags?.users_beta_companies).has(profile?.company?.id ?? '');
};
