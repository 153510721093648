import { Badge } from '@mui/material';
import { withStyles } from '@mui/styles';

export const StyledBadge = withStyles(() => ({
  root: {
    position: 'absolute',
    top: '50%',
    right: 0,
  },
  badge: {
    top: 2,
    transform: 'translateY(-50%)',
    right: 24,
  },
}))(Badge);
