import type { FC } from 'react';

import { ArrowExternalIcon } from '@truvhq/ui';
import { clsx } from 'clsx';

export const InfoExternal: FC<{ className: string }> = ({ className }) => (
  <ArrowExternalIcon
    className={clsx(
      'pointer-events-none absolute right-6 top-1/2 mt-[1px] -translate-y-1/2 transform opacity-0 transition-opacity group-hover:opacity-100',
      className,
    )}
  />
);
