import type { Member, Role, RoleId } from 'api/types';

export const isDeclined = ({ is_approved }: Member) => is_approved === false;

export const isPending = (member: Member) =>
  !isDeclined(member) && (isNotApproved(member) || (member.invite && member.invite?.status !== 'completed'));

export const isActive = (member: Member) => !isDeclined(member) && !isPending(member);

export const isNotApproved = (member: Member | null) => member?.is_approved === null && !member?.invite;

export const ownerRole = { id: 'owner' as RoleId, name: 'Owner', description: 'Owner' };

export const getRoleList = (member: Member | undefined, roles?: Role[]) => {
  if (!roles) {
    return;
  }

  if (!member?.is_owner) {
    return roles;
  }

  return [...roles, ownerRole];
};

export const isRevokable = (member: Member) => isActive(member) && !member?.is_owner && member?.is_approved;

export const isDeletable = (member: Member) => !isActive(member) && !isNotApproved(member);
