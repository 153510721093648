import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { AnalyticsButton } from 'components/AnalyticsButton';
import { useImpersonation } from 'hooks/impersonation';
import { useAuth } from 'shared/lib/auth/useAuth';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    textAlign: 'center',
    zIndex: 1000,
  },
  inner: {
    marginLeft: 'auto',

    display: 'flex',
    width: 'fit-content',

    backgroundColor: '#ffc6c6',
    borderBottomLeftRadius: '8px',

    paddingLeft: '16px',
    paddingRight: '16px',
    alignItems: 'center',
    zIndex: 1000,
  },
}));

export function ImpersonationBar() {
  const classes = useStyles();

  const { isAuthenticated } = useAuth();

  const { email, stopImpersonation } = useImpersonation();

  const isShown = Boolean(email) && isAuthenticated;

  if (!isShown) {
    return <></>;
  }

  return (
    <Box className={classes.root} width={1}>
      <Box className={classes.inner}>
        You are logged in as {email}
        <AnalyticsButton color="inherit" eventName="impersonation bar - click stop" onClick={stopImpersonation}>
          Stop
        </AnalyticsButton>
      </Box>
    </Box>
  );
}
