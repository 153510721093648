import type { FC } from 'react';

import { ErrorView } from './ErrorView';

export const NoPermissionAtAllView: FC = () => (
  <ErrorView
    pageTitle="Access denied"
    subTitle="Please ask your administrator to add required permissions to your user."
    title="Seems like you do not have access to any page"
  />
);
