import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiClient } from 'api/ApiClient';

import type { CompleteFormInfo, CompleteFormOptions } from './common';

async function sendInfoToZap({ firstName, lastName, companyName, email, role, type }: CompleteFormInfo) {
  const zapUrl = import.meta.env.VITE_APP_ONBOARDING_ZAPIER;

  if (!zapUrl) {
    return Promise.resolve({});
  }

  try {
    return await fetch(zapUrl, {
      method: 'POST',
      headers: {},
      body: JSON.stringify({
        firstName,
        lastName,
        company: companyName,
        email,
        role,
        type,
      }),
    });
  } catch (error) {
    console.error('Error sending info to Zap:', error);

    return Promise.resolve({});
  }
}

function saveInfo(
  { firstName, lastName, companyName, role, type, email }: CompleteFormInfo,
  { updateCompany, companyId }: CompleteFormOptions = {},
) {
  return Promise.all([
    apiClient.updateProfile({
      first_name: firstName,
      last_name: lastName,
    }),
    ...(updateCompany && !!companyId
      ? [
          apiClient.updateCompanySettings(companyId, { name: companyName }),
          apiClient.updateWidgetSettings({
            client_name: companyName,
          }),
        ]
      : []),
    sendInfoToZap({ firstName, lastName, companyName, email, role, type }),
  ]);
}

export const useCompleteProfileInfo = ({ updateCompany, companyId, onSuccess }: CompleteFormOptions = {}) => {
  const queryClient = useQueryClient();
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: (newProfileData: CompleteFormInfo) => {
      return saveInfo(newProfileData, {
        updateCompany,
        companyId,
      });
    },
    onSuccess: ([data]) => {
      queryClient.setQueryData(['getProfile'], () => {
        return data;
      });
      void onSuccess?.();
    },
    onError: (error) => {
      console.error(error);
    },
  });

  return {
    complete: mutate,
    isPending,
    isSuccess,
  };
};
