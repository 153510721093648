import { normalizePhone } from './normalizePhone';

export function unpackPhone(phone: string) {
  const clearedPhone = normalizePhone(phone).replaceAll('(', '').replaceAll(')', '').replaceAll('-', '');

  if (clearedPhone.startsWith('+1')) {
    return clearedPhone.slice(2).substring(0, 10);
  }

  // in case of +2 or other country codes
  if (clearedPhone.startsWith('+')) {
    return clearedPhone.slice(1).substring(0, 10);
  }

  return clearedPhone.substring(0, 10);
}
