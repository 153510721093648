import { useContext } from 'react';

import { FiltersContext } from '../../contexts/FiltersContext';

export const useFiltersContext = () => {
  const context = useContext(FiltersContext);

  if (context === undefined) {
    throw new Error('useFiltersContext must be used within a FiltersContextProvider');
  }

  return context;
};
