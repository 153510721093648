import { useCallback } from 'react';

import { useSnackbar } from 'notistack';

export const useShowFileSizeError = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useCallback(
    () => enqueueSnackbar('Uploaded file is too large. Please select a file less than 2Mb'),
    [enqueueSnackbar],
  );
};
