import { Box, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@truvhq/ui';
import { colors } from '@truvhq/ui';

import underReviewImage from './images/under-review.png';
import { Footer } from '../UnverifiedEmail/Footer';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    textAlign: 'center',
    borderRadius: '6px',
    backgroundColor: colors.white,
    width: '832px',
    padding: '32px 28px',
    [theme.breakpoints.down('md')]: {
      width: '80%',
      minWidth: '360px',
    },
  },
  imageContainer: {
    backgroundColor: '#F5F5F5',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '48px',
    borderRadius: '8px',
  },
  subheader: {
    marginTop: '8px',
    padding: '0 32px',
  },
}));

export const UnapprovedUser = () => {
  const classes = useStyles();

  return (
    <div
      style={{
        flexGrow: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Grid
        container
        alignItems="center"
        direction="column"
        justifyContent="center"
        spacing={0}
        style={{ flexGrow: 1, height: '100%' }}
      >
        <div className={classes.container}>
          <Box className={classes.imageContainer}>
            <img alt="" src={underReviewImage} width={288} />
          </Box>
          <Box mt={4}>
            <Typography variant="h1">Your application is under review</Typography>
            <Typography className={classes.subheader} variant="body1">
              Keep an eye out for an approval email from Truv.
            </Typography>
          </Box>
        </div>
      </Grid>
      <Footer pageName="unapproved user" />
    </div>
  );
};
