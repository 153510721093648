import { useQuery, useQueryClient } from '@tanstack/react-query';

import type { State } from './types';

const SUBMENU_STATE_KEY = ['submenuState'];

export const useSubmenuState = (initialData: State) => {
  const queryClient = useQueryClient();

  const { data: submenuState } = useQuery<State>({
    queryKey: SUBMENU_STATE_KEY,
    initialData: () => initialData,
  });

  const setSubmenuState = (newState: State) => {
    queryClient.setQueryData(SUBMENU_STATE_KEY, newState);
  };

  return { submenuState, setSubmenuState };
};
