import { useEffect, useMemo } from 'react';

import type { TransitionProps } from '@mui/material/transitions';
import { useModal } from 'react-modal-hook';

import { useFeatures } from 'hooks/features';

import { FannieHeader } from 'components/Promo/FannieHeader';
import { FannieModal } from 'components/Promo/FannieModal';
import { FreddieHeader } from 'components/Promo/FreddieHeader';
import { FreddieModal } from 'components/Promo/FreddieModal';

export enum Promo {
  FREDDIE_POPUP = 'freddie-popup',
  FREDDIE_HEADER = 'freddie-header',
  FANNIE_POPUP = 'fannie-popup',
  FANNIE_HEADER = 'fannie-header',
}

export type PromoConfig = {
  id: Promo;
  enabled?: boolean;
  interval?: number;
  href?: string;
};

const isPromoActive = (promo: PromoConfig) => {
  if (promo.enabled === false) {
    return false;
  }

  let localPromo = null;

  try {
    localPromo = localStorage.getItem(`promo_${promo.id}`);
  } catch (_) {
    //
  }

  if (localPromo === 'false') {
    return false;
  }

  const snoozeTime = (promo.interval ?? 0) * 1000;

  if (snoozeTime && localPromo && Date.now() < Number(localPromo) + snoozeTime) {
    return false;
  }

  return true;
};

const setLocalPromo = (id: Promo, value: string) => {
  try {
    localStorage.setItem(`promo_${id}`, value);
  } catch (e) {
    //
  }
};

const setPromoShown = (id: Promo) => {
  setLocalPromo(id, Date.now().toString());
};

const setPromoClosed = (id: Promo) => {
  setLocalPromo(id, 'false');
};

const FREDDIE_LINK = 'https://truv.com/freddie-mac-approved';
const FANNIE_LINK = 'https://truv.com/truv-teams-up-with-fannie-mae-to-revolutionize-borrower-verifications';

export const usePromo = () => {
  const { data: features } = useFeatures();

  const activePromo = useMemo(() => features?.promo?.find((promo) => isPromoActive(promo)), [features]);

  const [showPromoModal, hidePromoModal] = useModal(
    ({ in: open = false }: TransitionProps) => {
      if (activePromo?.id === Promo.FANNIE_POPUP) {
        return (
          <FannieModal
            href={activePromo.href ?? FANNIE_LINK}
            open={open}
            onClick={() => {
              setPromoClosed(Promo.FANNIE_POPUP);
              setPromoClosed(Promo.FANNIE_HEADER);
            }}
            onClose={hidePromoModal}
          />
        );
      }

      if (activePromo?.id === Promo.FREDDIE_POPUP) {
        return (
          <FreddieModal
            href={activePromo.href ?? FREDDIE_LINK}
            open={open}
            onClick={() => {
              setPromoClosed(Promo.FREDDIE_POPUP);
              setPromoClosed(Promo.FREDDIE_HEADER);
            }}
            onClose={hidePromoModal}
          />
        );
      }
    },
    [activePromo],
  );

  useEffect(() => {
    if (!activePromo?.id) {
      return;
    }

    showPromoModal();
    setPromoShown(activePromo.id);
  }, [showPromoModal, activePromo]);

  let promoHeader = null;

  if (activePromo?.id === Promo.FANNIE_HEADER) {
    promoHeader = (
      <FannieHeader
        href={activePromo?.href ?? FANNIE_LINK}
        onClick={() => {
          setPromoClosed(Promo.FANNIE_POPUP);
          setPromoClosed(Promo.FANNIE_HEADER);
        }}
      />
    );
  }

  if (activePromo?.id === Promo.FREDDIE_HEADER) {
    promoHeader = (
      <FreddieHeader
        href={activePromo?.href ?? FREDDIE_LINK}
        onClick={() => {
          setPromoClosed(Promo.FREDDIE_POPUP);
          setPromoClosed(Promo.FREDDIE_HEADER);
        }}
      />
    );
  }

  return {
    activePromo,
    promoHeader,
  };
};
