import type { FC } from 'react';

import { ErrorView } from './ErrorView';

export const NoPermissionView: FC = () => (
  <ErrorView
    buttonText="Back to home"
    linkTo="/"
    pageTitle="Access denied"
    subTitle="You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation."
    title="Seems like you do not have the required permission for this page"
  />
);
