import type { Permission } from 'types/permissions';

type UrlInfo = {
  title: string;
  url: () => string;
  oldUrl?: string;
  permission?: Permission;
  companyPermission?: Permission;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parameterUrl?: (...args: any[]) => string;
};

type UrlKey =
  | 'home'
  | 'reporting'
  | 'usage'
  | 'performance'
  | 'managers'
  | 'activity'
  | 'coverage'
  | 'allUsers'
  | 'userInformation'
  | 'users'
  | 'user'
  | 'orders'
  | 'orderInformation'
  | 'orderCreate'
  | 'ordersUpload'
  | 'development'
  | 'tasks'
  | 'keys'
  | 'generateKeys'
  | 'platformKeys'
  | 'platforms'
  | 'logs'
  | 'webhooks'
  | 'createWebhook'
  | 'editWebhook'
  | 'systemStatus'
  | 'customization'
  | 'marketplace'
  | 'externalIntegration'
  | 'customSettings'
  | 'templates'
  | 'templateCreate'
  | 'templateEdit'
  | 'fieldsCustomization'
  | 'emulator'
  | 'workflows'
  | 'supportTickets'
  | 'createSupportTicket'
  | 'supportSelectCategory'
  | 'supportFillDetails'
  | 'ticketInformation'
  | 'account'
  | 'branding'
  | 'docs'
  | 'settings'
  | 'company'
  | 'team'
  | 'addMember'
  | 'editMember'
  | 'billing'
  | 'profile'
  | 'twilioIntegration'
  | 'verification'
  | 'verificationStatus';

export const urls: Record<UrlKey, UrlInfo> = {
  home: {
    title: 'Home',
    url: () => '/app/home',
  },

  reporting: {
    title: 'Reporting',
    url: () => '/app/home/reporting',
  },

  usage: {
    title: 'Usage',
    url: () => '/app/home/usage',
    oldUrl: '/app/settings/usage',
    permission: 'can_edit_company_settings', // owners and admins only
  },

  performance: {
    title: 'Monthly performance',
    url: () => '/app/home/performance',
    permission: 'can_view_performance_reports',
  },

  managers: {
    title: 'Team performance',
    url: () => '/app/home/managers',
    permission: 'can_view_orders',
  },

  emulator: {
    title: 'Emulator',
    url: () => '/app/emulator',
    permission: 'can_view_development',
  },

  /**
   * Activity menu
   */
  activity: {
    title: 'Activity',
    url: () => '/app/usage',
  },

  allUsers: {
    title: 'All users',
    url: () => '/app/usage/all-users',
    permission: 'can_view_profiles',
  },

  userInformation: {
    title: 'User information',
    url: () => '/app/usage/all-users/:id',
    permission: 'can_view_profiles',
  },

  users: {
    title: 'Users',
    url: () => '/app/usage/users',
    oldUrl: '/app/users',
    permission: 'can_view_profiles',
  },

  user: {
    title: 'User',
    url: () => '/app/usage/users/:id',
    permission: 'can_view_profiles',
  },

  orders: {
    title: 'Orders',
    url: () => '/app/usage/orders',
    oldUrl: '/app/orders',
    permission: 'can_view_orders',
  },

  orderInformation: {
    title: 'Order information',
    permission: 'can_view_orders',
    url: () => '/app/usage/orders/view/:orderId/',
  },

  orderCreate: {
    title: 'Create order',
    permission: 'can_view_orders',
    url: () => '/app/usage/orders/create/',
  },

  ordersUpload: {
    title: 'Upload orders',
    permission: 'can_view_orders',
    url: () => '/app/usage/orders/upload/',
  },

  tasks: {
    title: 'Tasks',
    parameterUrl: (taskId = ':taskId?') => `/app/usage/tasks/${taskId}/`,
    url: () => '/app/usage/tasks',
    oldUrl: '/app/development/tasks',
    permission: 'can_view_tasks',
  },

  coverage: {
    title: 'Coverage',
    url: () => '/app/usage/coverage',
    oldUrl: '/app/coverage',
    permission: 'can_view_analytics',
  },

  /**
   * Development menu
   */
  development: {
    title: 'Development',
    url: () => '/app/development',
    permission: 'can_view_development',
  },

  workflows: {
    title: 'UX guides',
    url: () => 'https://docs.truv.com/',
    oldUrl: '/app/development/workflows',
    permission: 'can_view_development',
  },

  keys: {
    title: 'API keys',
    url: () => '/app/development/keys',
    permission: 'can_view_keys',
  },

  generateKeys: {
    url: () => '/app/development/keys/generate',
    title: 'Generate new key',
    permission: 'can_view_keys',
  },

  platformKeys: {
    url: () => '/app/development/platforms/:id',
    title: 'Platform keys form',
    permission: 'can_view_platform_keys',
  },

  platforms: {
    url: () => '/app/development/platforms',
    title: 'Platform keys',
    permission: 'can_view_platform_keys',
  },

  logs: {
    title: 'Logs',
    url: () => '/app/development/logs',
    permission: 'can_view_logs',
  },

  webhooks: {
    title: 'Webhooks',
    url: () => '/app/development/webhooks',
    permission: 'can_view_webhooks',
  },

  createWebhook: {
    title: 'Create webhook',
    url: () => '/app/development/webhooks/create',
    permission: 'can_view_webhooks',
  },

  editWebhook: {
    title: 'Edit webhook',
    url: () => '/app/development/webhooks/:id',
    permission: 'can_view_webhooks',
  },

  systemStatus: {
    title: 'System status',
    url: () => '/app/development/system-status',
    permission: 'can_view_provider_status',
  },

  /**
   * Customization menu
   */
  customization: {
    title: 'Customization',
    url: () => '/app/customization',
    permission: 'can_view_customization',
  },

  marketplace: {
    title: 'Marketplace',
    url: () => '/app/customization/marketplace',
    permission: 'can_edit_company_settings',
  },

  externalIntegration: {
    title: 'External integration',
    url: () => '/app/customization/integration/:id',
    permission: 'can_edit_company_settings',
  },

  account: {
    title: 'Account',
    url: () => '/app/customization/account',
    permission: 'can_view_customization',
  },

  branding: {
    title: 'Branding',
    url: () => '/app/customization/account/branding',
    permission: 'can_view_customization',
  },

  customSettings: {
    title: 'Custom settings',
    url: () => '/app/customization/account/custom',
    oldUrl: '/app/settings/custom',
    permission: 'can_edit_customization',
  },

  twilioIntegration: {
    title: 'Twilio integration',
    url: () => '/app/customization/account/twilio',
    permission: 'can_edit_company_settings',
  },

  templateCreate: {
    title: 'Create template',
    url: () => '/app/customization/templates/create',
    permission: 'can_edit_customization',
  },

  templateEdit: {
    title: 'Edit template',
    url: () => '/app/customization/templates/:id',
    permission: 'can_edit_customization',
  },

  templates: {
    title: 'Templates',
    url: () => '/app/customization/templates',
    permission: 'can_edit_customization',
  },

  fieldsCustomization: {
    title: 'Data',
    url: () => '/app/customization/data',
    oldUrl: '/app/settings/fields',
    permission: 'can_view_customization',
  },

  /**
   * Help menu
   */
  supportTickets: {
    title: 'Support',
    url: () => '/app/support',
    permission: 'can_view_support_tickets',
  },

  createSupportTicket: {
    title: 'Create ticket',
    url: () => '/app/support/create',
    permission: 'can_view_support_tickets',
  },
  supportSelectCategory: {
    title: 'Select category',
    url: () => '/app/support/create/:type/:category_id',
    permission: 'can_view_support_tickets',
  },
  supportFillDetails: {
    title: 'Select issue',
    url: () => '/app/support/create/:type/:category_id/:subcategory_id/details',
    permission: 'can_view_support_tickets',
  },

  ticketInformation: {
    title: 'Ticket information',
    url: () => '/app/support/:id',
    permission: 'can_view_support_tickets',
  },

  docs: {
    title: 'Docs',
    url: () => 'https://docs.truv.com/login?redirect_uri=/',
    parameterUrl: (isOrdersManager: boolean) =>
      isOrdersManager ? 'https://docs.truv.com/docs/orders-guide' : urls.docs.url(),
  },

  /**
   * Settings menu
   */
  settings: {
    title: 'Settings',
    url: () => '/app/settings',
  },

  company: {
    title: 'Company',
    url: () => '/app/settings/company',
    permission: 'can_view_company_settings',
  },

  addMember: {
    title: 'Add a team member',
    url: () => '/app/settings/team/add',
    permission: 'can_invite_users',
  },

  editMember: {
    title: 'Edit a team member',
    url: () => '/app/settings/team/member/:id',
    permission: 'can_invite_users',
  },

  team: {
    title: 'Team',
    url: () => '/app/settings/team/:tab?',
    parameterUrl: () => '/app/settings/team',
    permission: 'can_invite_users',
  },

  billing: {
    title: 'Billing',
    url: () => '/app/settings/billing',
    permission: 'can_view_billing',
  },

  profile: {
    title: 'Profile',
    url: () => '/app/settings/profile',
    oldUrl: '/app/profile',
    permission: 'can_view_profile',
  },

  /**
   * no auth required
   */
  verificationStatus: {
    title: 'Income and employment verification',
    url: () => '/verification/:id',
  },

  verification: {
    title: 'Employment verification',
    url: () => '/verification/',
  },
} as const;
