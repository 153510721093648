import { UTCDateMini } from '@date-fns/utc';
import { captureException } from '@sentry/react';
import {
  addDays,
  endOfDay,
  formatDuration as fnsFormatDuration,
  isSameYear as fnsIsSameYear,
  intervalToDuration,
  isValid,
  startOfDay,
  startOfISOWeek,
} from 'date-fns';
import { enUS } from 'date-fns/locale';
import { format } from 'date-fns-tz';

export const DATE_FORMAT = 'MMM d, yyyy';
export const SHORT_DATE_FORMAT = 'MM/dd/yyyy';
export const FILTER_DATE_FORMAT = 'MM/dd/yy';

export const API_DATE_FORMAT = 'yyyy-MM-dd';

export const TIME_FORMAT = 'h:mm:ss a';

type DateType = Date | number | string | null;

export const formatDate = (date?: DateType, utc = false, dateFormat = DATE_FORMAT) => {
  if (!date) {
    return '–';
  }

  const fnsDate = utc ? new UTCDateMini(date) : new Date(date);

  if (!isValid(fnsDate)) {
    return '–';
  }

  try {
    return format(fnsDate, dateFormat);
  } catch (error) {
    console.error(error);
    captureException(error);

    return '–';
  }
};

export const formatDateTime = (date?: DateType, dateFormat = DATE_FORMAT, timeFormat = TIME_FORMAT) => {
  if (!date) {
    return '–';
  }

  const fnsDate = new Date(date);

  if (!isValid(fnsDate)) {
    return '–';
  }

  try {
    return format(fnsDate, `${dateFormat}, ${timeFormat} z`, { locale: enUS });
  } catch (error) {
    console.error(error);
    captureException(error);

    return '–';
  }
};

export const formatDateTimeShort = (date?: DateType, dateFormat = DATE_FORMAT) => {
  if (!date) {
    return '–';
  }

  const fnsDate = new Date(date);

  if (!isValid(fnsDate)) {
    return '–';
  }

  try {
    return format(fnsDate, `${dateFormat}, h:mm a`);
  } catch (error) {
    console.error(error);
    captureException(error);

    return '–';
  }
};

export function getPeriodStart(days: number, dateFormat = API_DATE_FORMAT) {
  return format(addDays(startOfDay(new Date()), days), dateFormat);
}

export function getPeriodEnd(days: number, dateFormat = API_DATE_FORMAT) {
  return format(addDays(endOfDay(new Date()), days), dateFormat);
}

export function getWeekStart(dateFormat = API_DATE_FORMAT) {
  return format(startOfISOWeek(new Date()), dateFormat);
}

export function isSameYear(date1?: DateType, date2?: DateType) {
  return !!date1 && !!date2 && fnsIsSameYear(date1, date2);
}

export const formatDuration = (ms: number) => {
  const duration = intervalToDuration({
    start: 0,
    end: ms,
  });

  const { hours, minutes, seconds } = duration;

  if (!hours && !minutes && !seconds) {
    return 'now';
  }

  return `in ${fnsFormatDuration(duration)}`;
};
