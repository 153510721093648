import { formLabelClasses } from '@mui/material';
import type { SxProps } from '@mui/system/styleFunctionSx';
import { colors } from '@truvhq/ui';

import type { theme } from 'theme';

export const requiredFormFieldStyles: SxProps<typeof theme> = {
  [`.${formLabelClasses.asterisk}`]: {
    color: colors.red.text,
  },
};
