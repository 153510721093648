import type { FC } from 'react';

import { Box } from '@mui/material';

type Props = {
  href: string;
  onClick: () => void;
};

export const FannieHeader: FC<Props> = ({ href, onClick }) => (
  <Box
    className="group hidden h-[68px] w-full flex-nowrap items-center justify-center text-center text-white no-underline sm:flex"
    component="a"
    href={href}
    rel="noreferrer"
    sx={{
      backgroundImage: 'linear-gradient(90deg, #224185 0%, #254a9e 15%, #203565 50%, #2855bb 70%, #223c79 100%)',
    }}
    target="_blank"
    onClick={onClick}
  >
    <div className="px-4 text-xs xl:text-sm">
      <div className="mx-auto w-fit border-b border-b-white opacity-90 group-hover:opacity-100">
        Truv now supports Day&nbsp;1&nbsp;Certainty® from Fannie&nbsp;Mae
      </div>
      <div className="opacity-70 group-hover:opacity-100">
        Truv is the first consumer permissioned platform to support both Fannie Mae’s Desktop Underwriter® validation
        service and Freddie Mac’s Loan Product Advisor®&nbsp;(LPASM) asset&nbsp;and&nbsp;income&nbsp;modeler&nbsp;(AIM)
      </div>
    </div>
  </Box>
);
