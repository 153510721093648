import { useQuery } from '@tanstack/react-query';

import { apiClient } from 'api/ApiClient';

export const useProfileSuggest = () => {
  const { data } = useQuery({ queryKey: ['getProfileSuggest'], queryFn: () => apiClient.getProfileSuggest() });

  return {
    suggest: data,
  };
};
