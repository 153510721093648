import type { FC } from 'react';

import { Button, Dialog, DialogContent, DialogTitle, IconButton, ThemeProvider, Typography } from '@mui/material';
import { CloseIcon, getTheme } from '@truvhq/ui';

import freddieBg from 'assets/images/promo/freddie-bg.svg';
import FreddieLogo from 'assets/images/promo/freddie-logo.svg?react';
import freddiePreview from 'assets/images/promo/freddie-preview.png';

const promoTheme = getTheme({ primaryColor: '#4477EB' });

type Props = {
  href: string;
  onClick: () => void;
  onClose: () => void;
  open: boolean;
};

export const FreddieModal: FC<Props> = ({ href, onClick, onClose, open }) => (
  <Dialog
    fullWidth
    PaperProps={{
      className: 'max-w-[560px] bg-grey90 overflow-hidden z-0 rounded-3xl',
      sx: {
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
          background: `url("${freddieBg}") 0 0 no-repeat`,
        },
      },
    }}
    open={open}
  >
    <DialogTitle className="px-4 pb-2 text-right">
      <IconButton aria-label="close" onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent className="flex flex-col items-center pb-6 text-center">
      <FreddieLogo className="shrink-0" />
      <Typography className="mt-9 bg-gradient-to-r from-white to-primary-main bg-clip-text text-[36px] font-bold leading-tight tracking-[-0.36px] text-transparent">
        Truv is now approved
        <br />
        with Freddie Mac
      </Typography>
      <Typography className="mt-[14px] max-w-[380px] text-grey50">
        Truv offers instant income and employment verification through Freddie Mac Loan Product Advisor® asset and
        income modeler (AIM).
      </Typography>
      <ThemeProvider theme={promoTheme}>
        <Button
          className="mt-8 h-12 shrink-0 px-6 py-3 text-[16px] leading-6 shadow-none hover:shadow-none"
          href={href}
          target="_blank"
          variant="contained"
          onClick={onClick}
        >
          View announcement
        </Button>
      </ThemeProvider>
      <img alt="Truv is now approved with Freddie Mac" className="mt-[66px] h-auto w-full" src={freddiePreview} />
    </DialogContent>
  </Dialog>
);
